<template>
  <div class="product">
    <NavBer></NavBer>
    <div>
      <el-carousel height="500px" :autoplay="true" arrow="never" :interval="3000">
          <el-carousel-item v-for="(item,index) in Rotation" :key="index">
            <div class="rotationimg">
              <img :src="item.url" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
    </div>
   
    <div class="w content">
      <el-row>
        <el-col :span="8">
          <div class="grid-content">
            <img src="../assets/images/product/product1.jpg" alt="">
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bgs">
            <h4 class="commodity">美沙拉秦缓释颗粒</h4>
            <hr />
            <div><span>【产品名称】</span> <span>艾迪莎</span></div>
            <div><span>【批准文号】</span> <span>国药准字 H20143164</span></div>

            <div>
              <span>【功能主治】</span>
              <span>	1、溃疡性结肠炎，用于溃疡性结肠炎的急性发作，防止复发。2、克罗恩病，用于频繁发发病的克罗恩病病人，预防急性发作。</span>

            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="grid-content">
            <img src="../assets/images/product/product2.jpg" alt="">
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bgs">
            <h4 class="commodity">固本益肠片</h4>
            <hr />
            <div><span>【产品名称】</span> <span>固本益肠片</span></div>
            <div><span>【批准文号】</span> <span>国药准字 Z10940035</span></div>

            <div>
              <span>【功能主治】</span>
              <span>健脾温肾，涩肠止泻。用于脾肾阳虚所致的泄泻，症见腹痛绵绵、大便清稀或有粘液及粘液血便，食少腹胀，腰酸乏力，形寒肢冷，舌淡苔白，脉虚；慢性肠炎见上述证候者。</span>

            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <div class="grid-content">
            <img src="../assets/images/product/product3.jpg" alt="">
          </div>
        </el-col>
        <el-col :span="16">
          <div class="grid-content bgs">
            <h4 class="commodity">香砂养胃丸(浓缩丸)</h4>
            <hr />
            <div><span>【产品名称】</span> <span>香砂养胃丸(浓缩丸)</span></div>
            <div><span>【批准文号】</span> <span>国药准字 Z41021829</span></div>
            <div>
              <span>【功能主治】</span>
              <span>温中和胃。用于不思饮食，胃脘满闷或泛吐酸水。</span>
            </div>
          </div>
        </el-col>
      </el-row>
      



    </div>
    <Footbar></Footbar>
  </div>
</template>

<script>
import NavBer from "../components/nav";
import Footbar from "../components/footbar";
export default {
  name: "product",
  data() {
    return {
      Rotation: [
       {
          url: require('../assets/images/product/product1.jpg')
        },
        {
          url: require('../assets/images/product/product2.jpg')
        },
        {
          url: require('../assets/images/product/product3.jpg')
        }

      ]
    };
  },
  components: {
    NavBer,
    Footbar,
  },
};
</script>

<style scoped>
.product {
  padding-top: 90px;
}
.el-carousel__item h3 {
  height: 500px;
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 500px;
    margin: 0;
  }
  
  .rotationimg {
    /* width: 600px; */
    /* height: 300px; */
    text-align: center;
    /* margin: 0 auto; */
    margin-top: 100px;
  }
  .rotationimg img {
    width: 500px;
    height: 400px;
    /* margin: o auto; */
  }

.bg1 {
  width: 100%;
  height: 500px;
  margin-bottom: 10px;
}

.bg1 img {
  width: 100%;
  height: 100%;
}

.content {
  margin-top: 60px;

}

.commodity {
  margin: 20px 0 10px 0;
}

.bgs {
  /* background-color: pink; */
  padding-left: 100px;
  padding-right: 100px;
}

.el-row {
  margin-bottom: 60px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.grid-content {
  border-radius: 4px;
  height: 260px;
  /* border: solid 1px #333; */
}

.grid-content img {
  /* width: 100%; */
  height: 100%;
}
</style>
